var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.findConfigUpwards = findConfigUpwards;
  exports.findPackageData = findPackageData;
  exports.findRelativeConfig = findRelativeConfig;
  exports.findRootConfig = findRootConfig;
  exports.loadConfig = loadConfig;
  exports.resolveShowConfigPath = resolveShowConfigPath;
  exports.resolvePlugin = resolvePlugin;
  exports.resolvePreset = resolvePreset;
  exports.loadPlugin = loadPlugin;
  exports.loadPreset = loadPreset;
  exports.ROOT_CONFIG_FILENAMES = void 0;

  function* findConfigUpwards(rootDir) {
    return null;
  }

  function* findPackageData(filepath) {
    return {
      filepath,
      directories: [],
      pkg: null,
      isPackage: false
    };
  }

  function* findRelativeConfig(pkgData, envName, caller) {
    return {
      pkg: null,
      config: null,
      ignore: null
    };
  }

  function* findRootConfig(dirname, envName, caller) {
    return null;
  }

  function* loadConfig(name, dirname, envName, caller) {
    throw new Error(`Cannot load ${name} relative to ${dirname} in a browser`);
  }

  function* resolveShowConfigPath(dirname) {
    return null;
  }

  const ROOT_CONFIG_FILENAMES = [];
  exports.ROOT_CONFIG_FILENAMES = ROOT_CONFIG_FILENAMES;

  function resolvePlugin(name, dirname) {
    return null;
  }

  function resolvePreset(name, dirname) {
    return null;
  }

  function loadPlugin(name, dirname) {
    throw new Error(`Cannot load plugin ${name} relative to ${dirname} in a browser`);
  }

  function loadPreset(name, dirname) {
    throw new Error(`Cannot load preset ${name} relative to ${dirname} in a browser`);
  }

  return exports;
}